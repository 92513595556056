/**
 * article模块接口列表
 */

import base from './base' // 导入接口域名列表
// import axios from 'axios' // 导入http中创建的axios实例
import axios from '@/request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const register = {

  // post提交
  register (params) {
    return axios.post(`${base.register}/register`, qs.stringify(params))
  },
  // post提交
  isRegistered (params) {
    return axios.post(`${base.register}/isRegistered`, qs.stringify(params))
  }
  // 其他接口…………
}

export default register
