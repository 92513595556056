<template>
  <div>
    <div class="catlist" @click="toCatid(cat.id)">
      <img v-if="cat.thumb" :src="cat.thumb" alt="" />
      <img v-else src="@/assets/logo.png" width="100%" height="125vw" />

      <div class="catlist-top">
        <van-tag type="danger">{{ cat.cate_name }} </van-tag>
      </div>
      <!-- <div class="catlist-bottom">
        <div class="catlist-title">
          {{ cat.cate_name }}
        </div>
      </div> -->

      <!-- <van-row>
        <van-col span="24"> </van-col>
      </van-row>
      <div class="course-top">
        <van-tag type="danger">{{ cat.catname }} </van-tag>
      </div>
      <div class="course-bottom">
        <div class="course-title">
          {{ cat.cate_name }}
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>

export default {
  props: ['cat'],
  beforeMount () {
    // this.$store.commit('hideShowTabbar', false)
  },
  components: {},
  data () {
    return {}
  },
  mounted () {
    console.log(this.cat, 'cat.vue')
  },
  methods: {
    toCatid (catid) {
      console.log(catid)

      // this.classid = localStorage.getItem('classid')
      //     // console.log(this.courseslist, 'courseslist')
      //     console.log(this.getCategories, 'getCategories.')
      //     console.log(typeof this.getCategories, 'typeof getCategories.')

      //     if (this.getCategories === undefined || (typeof this.getCategories === 'object' && this.getCategories.length === 0)) {
      //       this.uid = localStorage.getItem('uid')
      //       this.$store.dispatch('getCategories', {
      //         uid: this.uid
      //       })
      //       //   console.log(this.$store.state.article.catlist, 'state catlist')
      //     }

      if (this.categoryID === undefined || (typeof this.categoryID === 'object' && this.categoryID.length === 0) || (typeof this.categoryID === 'object' && this.categoryID.catInfo.id !== catid)) {
        this.$store.commit('getCategoryByCatID', '')
        localStorage.setItem('categoryID', '')

        this.$store.dispatch('getCategoryByCatID', {
          catid: catid,
          model: 1
        })

        //   console.log(this.$store.state.article.catlist, 'state catlist')
        console.log('重新请求')
      }

      // this.$store.dispatch('getCategoryByCatID', {
      //   catid: 1,
      //   model: 1
      // })
      // localStorage.setItem('categoryID', JSON.stringify(res.data))
      //   article: {
      //   catlist: [],
      //   catid: [],
      this.$router.push('/categoryid')
    }
  },
  computed: {
    categoryID () {
      let categoryID = this.$store.state.article.catid
      if (categoryID === null) {
        categoryID = []
      }
      //   console.log(categoryID, 'dddd')
      if (categoryID !== []) {
        return categoryID
      } else {
        categoryID = localStorage.getItem('categoryID')
      }
      var categoryIDArr = []
      if (categoryID !== null) {
        categoryIDArr = JSON.parse(categoryID)
      }
      return categoryIDArr// .courselist
    }
  }
}
</script>
<style lang="scss" scoped>
li,
div,
img {
  margin: 0vw;
  padding: 0vw;
}
.catlist {
  margin: 0vw;
  //   padding: 5px;
  //   margin-bottom: -2.778vw;
  //   background-color: aqua;
  //   overflow: hidden;
  position: relative;
  float: left;
  //   clear: both;
  width: 50%;
  img {
    width: 96%;
    // width="100%"
    height: 27.778vw;
    padding-top: 1.389vw;
  }
  //   background-color: black;
  .catlist-top {
    position: absolute;
    // background-color: aquamarine;
    top: 2.778vw;
  }
  .catlist-bottom {
    position: absolute;
    bottom: 0vw;
    height: 11.111vw;
    padding-left: 2.778vw;
    width: 50%;
    .catlist-title {
      color: rgb(100, 9, 9);
      font-size: 3.611vw;
      font-weight: bold;
      padding: 0.556vw;
    }
    .van-card__title {
      font-size: 3.056vw;
      color: rgb(97, 14, 14);
      padding-left: 5.556vw;
      padding-top: 1.389vw;
    }
  }
}
</style>
