<template>
  <!-- <div id="content" :style="backgroundDiv"> -->
  <div id="content">
    <!-- <NavbarCommon :title="title"></NavbarCommon> -->
    <ul
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="loading"
      infinite-scroll-distance="10"
      v-if="this.courseByClassidList.courselist !== undefined"
    >
      <!-- <Courseinlist></Courseinlist> -->
      <Courseinlist
        :courses="
          this.$store.state.study.courseByClassidList.courselist.courses
        "
      ></Courseinlist>
    </ul>
    <van-loading v-else size="24px" vertical>课程列表加载中...</van-loading>
  </div>
</template>
<script>
// import NavbarCommon from '@/components/common/NavbarCommon'
import Courseinlist from '@/components/course/CourseInList'
export default ({

  data () {
    return {
      getId: '',
      getWith: ''
    }
  },
  components: {
    Courseinlist
  },
  computed: {
    courseByClassidList () {
      let courseByClassidList = this.$store.state.study.courseByClassidList// .courselist
      if (courseByClassidList === null) {
        courseByClassidList = []
      }
      //   console.log(courseByClassidList, 'dddd')
      if (courseByClassidList !== []) {
        return courseByClassidList
      } else {
        courseByClassidList = localStorage.getItem('courseByClassidList')
      }
      var courseByClassidListArr = []
      if (courseByClassidList !== null) {
        courseByClassidListArr = JSON.parse(courseByClassidList)
      }
      return courseByClassidListArr// .courselist
    },

    title () {
      var getWith = localStorage.getItem('getWith')
      if (getWith === 'classid') {
        return localStorage.getItem('classname')
      } else {
        return localStorage.getItem('categaryName')
      }
    }
  },

  watch: {
    courseByClassidList () {
      console.log(this.$store.state.study.courseByClassidList, 'state watch')
      this.$forceUpdate()// length
    }
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', true)
  },
  mounted () {
    // console.log(this.courseslist, 'courseslist')
    console.log(this.courseByClassidList, 'courseByClassidList.')
    console.log(typeof this.courseByClassidList, 'typeof courseByClassidList.')
    this.getWith = localStorage.getItem('getWith')
    this.getId = localStorage.getItem('classid')
    if (this.courseByClassidList === undefined || (typeof this.courseByClassidList === 'object' && this.courseByClassidList.length === 0)) {
      this.$store.dispatch('getCoursesListAction', {
        getId: this.getId,
        getWith: this.getWith,
        getModel: 'ONLY'
      })
      console.log(this.$store.state.study.courseByClassidList, 'state courseslist')
    }
  },
  methods: {
    loadMore () {
      this.loading = true
    }
    // toCourse () {
    //   this.$router.push('/course')
    // }
  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', true)
  }
  //   watch: {
  //     courseslist () {
  //       if (this.courseslist.length === 1) {
  //         this.toCourse()
  //       }
  //     }
  //   }
})
</script>

<style lang="scss" scoped>
#content {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 188.889vw;
}
</style>
