/**
 * test模块接口列表
 */

import base from './base' // 导入接口域名列表
// import axios from 'axios' // 导入http中创建的axios实例
import axios from '@/request/http' // 导入http中创建的axios实例
// import qs from 'qs' // 根据需求是否导入qs模块

const clockin = {
  // 试题数量
  getClockinList (params) {
    return axios.get(`${base.clockin}/getClockinList`, {
      params: params
    })
  },
  getClockin (params) {
    return axios.get(`${base.clockin}/getClockin`, {
      params: params
    })
  },
  dk (params) {
    return axios.get(`${base.clockin}/dk`, {
      params: params
    })
  }

  // 其他接口………… post(`${base.login}/login`, qs.stringify(params))
}

export default clockin
