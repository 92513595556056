<template>
  <div id="content" :style="backgroundDiv">
    <div class="title">五明广场</div>
    <Articlecatgory
      v-if="this.classid"
      :categories="this.getCategories"
    ></Articlecatgory>
    <div v-else>
      <van-notice-bar left-icon="volume-o" text="没有报名？" />
      <van-cell :title="title" is-link class="vancell" @click="joinclass" />
    </div>
  </div>
</template>

<script>
import Articlecatgory from '@/components/article/Articlecatgory'
export default {
  beforeMount () {
    this.$store.commit('hideShowTabbar', true)
  },
  components: { Articlecatgory },
  data () {
    return {
      uid: '',
      title: '先去报名吧！',
      classid: '',
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/pres.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }
    }
  },
  mounted () {
    this.classid = localStorage.getItem('classid')
    // console.log(this.courseslist, 'courseslist')
    console.log(this.getCategories, 'getCategories.')
    console.log(typeof this.getCategories, 'typeof getCategories.')

    if (this.getCategories === undefined || (typeof this.getCategories === 'object' && this.getCategories.length === 0)) {
      this.uid = localStorage.getItem('uid')
      this.$store.dispatch('getCategories', {
        uid: this.uid
      })
      //   console.log(this.$store.state.article.catlist, 'state catlist')
    }
  },
  methods: {
    joinclass () {
      this.$router.push('/joinclass')
    }
  },
  computed: {
    getCategories () {
      let getCategoriesList = this.$store.state.article.catlist// .courselist
      if (getCategoriesList === null) {
        getCategoriesList = []
      }
      //   console.log(courseByClassidList, 'dddd')
      if (getCategoriesList !== []) {
        return getCategoriesList
      } else {
        getCategoriesList = localStorage.getItem('categories')
      }
      var getCategoriesListArr = []
      if (getCategoriesList !== null) {
        getCategoriesListArr = JSON.parse(getCategoriesList)
      }
      return getCategoriesListArr// .courselist
    },
    heightData: {
      get () {
        console.log(this.autoHeight, 'autoHeight')
        return 68.889 + 'vw'
      },
      set () { }
    }
  }

}
</script>

<style lang="scss" scoped>
#content {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 188.889vw;
}
.title {
  color: white;
  background-color: rgb(70, 27, 27);
  line-height: 5.556vw;
  font-size: 4.167vw;
  height: 9.722vw;
  margin: 0 auto;
  padding-top: 4.167vw;
  text-align: center;
}
.vancell {
  //   border-top: 0.278vw #ccc solid;
  background-color: rgba(255, 255, 255, 0.959);
  margin-top: 4.167vw;
  margin-bottom: 2.778vw;
}
::v-deep .van-cell__title {
  color: chocolate;
}
</style>
