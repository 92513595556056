<template>
  <div>
    <ul infinite-scroll-disabled="loading" infinite-scroll-distance="10">
      <li
        v-for="classes in $store.state.study.classeslist"
        :key="classes.id"
        @click="toJoinclass(classes.id)"
        :style="backgroundDiv"
      >
        {{ classes.classname }}<br />( {{ classes.stime }} --
        {{ classes.gtime }} )
        <div v-if="classes.dsword" class="wordsFromHeSang">
          {{ classes.dsword }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      finished: false,
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/classlistbk.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }
    }
  },
  mounted () {
    // if (clslist === null) {
    //   clslist = []
    // }

  },
  computed: {
    // classesLength () {
    //   //   const clslist = localStorage.getItem('classeslist')
    //   //   //   localStorage.getItem('classeslist')
    //   //   //   let clslist = this.$store.study.classeslist
    //   //   console.log(clslist, '++++')
    //   //   if (clslist === null) {
    //   //     return false
    //   //   } else {
    //   return true
    //   //   }
    // },
    // starttime (time) {
    //   return this.formartDate(time)
    // }
  },
  methods: {
    // 报名
    toJoinclass (id) {
      this.$store.dispatch('Joinclass', {
        uid: localStorage.getItem('uid'),
        classid: id
      })
    }
  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', true)
  }

}
</script>

<style lang='scss' scoped>
.inline {
  display: flex;
  justify-content: space-between;
}
ul,
li {
  padding: 0vw;
  margin: 0vw;
  list-style: none;
}
ul {
  background-color: rgb(27, 8, 9);
  text-align: left;
  li {
    height: 16.667vw;
    padding: 8.333vw;
    color: #fff;
    .wordsFromHeSang {
      font-size: 3.333vw;
      color: yellow;
      padding-left: 5.556vw;
      padding-top: 2.778vw;
    }
  }
}
</style>
