<template>
  <div>
    <div v-for="cat in categories" :key="cat.id">
      <Cat :cat="cat"> </Cat>
    </div>

    <!-- <Pointslist
      :courseid="course.id"
      :classid="classid"
      :ctype="course.ctype"
    ></Pointslist> -->
  </div>
</template>

<script>
import Cat from '@/components/article/Cat'
// import Pointslist from '@/components/course/Pointslist'
export default ({
  components: {
    Cat
  },
  props: ['categories']

})
</script>
