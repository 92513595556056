import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
// import { Toast } from 'mint-ui'
Vue.use(Vuex)
const api = new Vue()

export default new Vuex.Store({
  state: {
    network: true,
    isShowTabBar: true,
    notice: '',
    isLogin: '',
    playervideo: false,
    playeraudio: false,
    video: {
      videosrc: '',
      id: 0
    },
    accesstoken: localStorage.getItem('accesstoken') ? localStorage.getItem('accesstoken') : '',
    userInfo: [],
    systeminfo: {
      Allclock: [],
      thisMonth: [],
      Today: [],
      test: []
    },
    isJoinclass: [],
    classeslistok: false,
    study: {
      classeslist: [],
      classid: 0,
      categaryList: [],
      courseByClassidList: [],
      courseByCatidList: [],
      course: [],
      pointslist: [],
      pointslistloading: false,
      point: [],
      pointQuestionNum: [],
      doneInfo: [],
      question: [],
      updateStudyInfo: false,
      AllOk: '',
      paper: [],
      papers: [],
      isClick: [],
      testQuestion: [],
      uidPaperInfo: [],
      testinfo: []
    },
    clockin: {
      clockinList: [],
      signin: []
    },
    article: {
      catlist: [],
      catid: [],
      article: []
    },
    actionary: {
      actionarylist: []
    },
    videoFirstPlay: true,
    editorData: []
  },
  mutations: {
    ClearState (state) {
      for (const i in state) {
        state[i] = []
      }
      state.isLogin = ''
      state.isShowTabBar = false
      state.network = true
      state.accesstoken = ''
      state.userInfo = []
      state.isJoinclass = []
      state.systeminfo = {}
      state.study = {}
      state.clockin = {}
    },
    changeNetwork (state, data) {
      state.network = data
    },
    playervideo (state, data) {
      state.playervideo = data
    },
    playeraudio (state, data) {
      state.playeraudio = data
    },
    getConfigDataMutation (state, data) {
    //   state.configList = data
    //   localStorage.setItem('configs', JSON.stringify(data))
      localStorage.setItem('configList', true)
      localStorage.setItem('CommentPageSize', data.CommentPageSize)
      localStorage.setItem('MultiNum', data.MultiNum)
      localStorage.setItem('PicUploadSize', data.PicUploadSize)
      localStorage.setItem('SingleNum', data.SingleNum)
      localStorage.setItem('SubjectiveNum', data.SubjectiveNum)
      localStorage.setItem('Times', data.Times)
      localStorage.setItem('ZyPageSize', data.ZyPageSize)
      localStorage.setItem('appName', data.appName)
      localStorage.setItem('appNameEnglish', data.appNameEnglish)
      localStorage.setItem('author', data.author)
      localStorage.setItem('description', data.description)
      localStorage.setItem('keywords', data.keywords)
      localStorage.setItem('stuPageSize', data.stuPageSize)
    },
    // 班级
    getClassesDataMutation (state, data) {
      state.study.classeslist = JSON.parse(JSON.stringify(data))
    },
    // 类别
    getCategaryDataMutation (state, data) {
      state.study.categaryList = data
    },
    // usierinfo
    getUserInfoMutation (state, data) {
      state.userInfo = data
    //   console.log(data, state.userInfo)
    },
    isJoinclass (state, data) {
      state.isJoinclass = data
    },
    classid (state, data) {
      state.classid = data
    },
    // videoFirstPlay (state, data) { getCoursesListAction
    //   state.videoFirstPlay = data
    // },
    // userInfo (state, data) {
    //   state.userInfo = data Joinclass
    // },
    // 更新个人信息
    updateUserInfoMutation (state, data) {
      if (data.field === 'name') state.userInfo.name = data.value
      else if (data.field === 'qq') state.userInfo.qq = data.value
      else if (data.field === 'phone') state.userInfo.phone = data.value
      else if (data.field === 'sex') state.userInfo.sex = data.value
      else if (data.field === 'education') state.userInfo.education = data.value
      else if (data.field === 'major') state.userInfo.major = data.value
      else if (data.field === 'email') state.userInfo.email = data.value
      else if (data.field === 'content') state.userInfo.content = data.value
    },
    // 显示 隐藏导航
    hideShowTabbar (state, data) {
      state.isShowTabBar = data
    },
    isLogin (state, data) {
      state.isLogin = data
    },
    // 改变登录状态
    changeLogin (state, user) {
      if (user.code === 301 || user.code === 302) {
        return
      }
      if (user.code === 203 || (user.code === 410)) {
        api.$msg({ message: user.msg })
        return
      }
      state.accesstoken = user.data.access_token
      localStorage.setItem('accesstoken', user.data.access_token)
      localStorage.setItem('uid', user.data.uid)
      if (user.status === 200) {
        state.isLogin = true
        state.isShowTabBar = true
        api.$msg({ message: user.msg })
        setTimeout(() => {
          router.push('/mine')
        }, 1000)
      }
    },
    getCourseListMutation (state, courselist) {
    //   console.log(courselist, '------state')
      if (courselist.getWith === 'classid') {
        state.study.courseByClassidList = JSON.parse(JSON.stringify(courselist))
        // console.log(state.study.courseByClassidList)
        // JSON.parse(JSON.stringify(imgLinkContent))
        localStorage.setItem('courseByClassidList', JSON.stringify(courselist))
      } else if (courselist.getWith === 'catid') {
        state.study.courseByCatidList = JSON.parse(JSON.stringify(courselist))
        // console.log(courselist, 'courselist')
        localStorage.setItem('courseByCatidList', JSON.stringify(courselist))
      }
    },
    getCourseDetailMutation (state, course) {
      state.study.course = JSON.parse(JSON.stringify(course))
    },
    getPointslistMutation (state, pointslist) {
      pointslist = JSON.parse(JSON.stringify(pointslist))
      if (state.study.pointslist.total > 0) {
        state.study.pointslist.data.push(...pointslist.data)
      } else {
        state.study.pointslist = pointslist
      }
      localStorage.setItem('pointslist', JSON.stringify(state.study.pointslist))
    },
    unsetPointslist (state, pointslist) {
      console.log('del')
      state.study.pointslist = pointslist
    },
    updatepointlist (state, pointslist) {
      state.study.pointslist = pointslist
      console.log(pointslist, 'pointslist update')
    },
    pointslistloading (state, data) {
      state.study.pointslistloading = data
    },
    getPointMutation (state, data) {
      state.study.point = data
    },
    upCurrentTimeOfpoint (state, data) {
      state.study.point.currenttime = data
    },
    videoFirstPlay (state, data) {
      state.videoFirstPlay = data
    },
    getDoneInfoMutation (state, data) {
      state.study.doneInfo = data
      state.study.getDoneInfoFinshed = true
    },
    getNumOfQuestionsMutation (state, data) {
      state.study.pointQuestionNum = data
    },
    getQuestionInfoMutation (state, data) {
      state.study.question = data
    },
    updateStudyInfoMutation (state, data) {
      state.study.updateStudyInfo = data
    },
    updateAllOkMutation (state, data) {
      state.study.AllOk = data
    },
    makePaperMutation (state, data) {
      state.study.paper = data
    },
    getPaperQuestionInfoMutation (state, data) {
      state.study.testQuestion = data
    },
    uidPaperInfo (state, data) {
      state.study.uidPaperInfo = data
    },
    isClick (state, data) {
    //   if (state.study.isClick.length > 0) {
    //     state.study.isClick.push(...data)
    //   } else {
      state.study.isClick = data
      //   }
      console.log(state.study.isClick, 'state isClic')
    //   state.study.isClick.push(...data)
    },
    ClockinList (state, data) {
      state.clockin.clockinList = data
    },
    getClockinMutation (state, data) {
      state.clockin.signin = data
    },
    TestinfoMutation (state, data) {
      state.study.testinfo = data
    },
    papers (state, data) {
      state.study.papers = data
    },
    Allclock (state, data) {
      state.systeminfo.Allclock = data
    },
    thisMonth (state, data) {
      state.systeminfo.thisMonth = data
    },
    Today (state, data) {
      state.systeminfo.Today = data
    },
    test (state, data) {
      state.systeminfo.test = data
    },
    updateScore (state, data) {
      if (data.feild === 'wisdom') { state.userInfo.wisdom = state.userInfo.wisdom + data.score }
      if (data.feild === 'ford') { state.userInfo.ford = state.userInfo.wisdom + data.score }
    },
    getCategories (state, data) {
      state.article.catlist = data
    },
    getCategoryByCatID (state, data) {
      state.article.catid = data
    },
    getArticleInfo (state, data) {
      state.article.article = data
    },
    unsetArticleInfo (state, data) {
      state.article.article = data
    },
    getVideoSrc (state, data) {
      if (data) {
        state.video.videosrc = data.url
        state.video.id = data.id
        console.log('video 1')
      } else {
        state.video = data
        console.log('video 2')
      }
    },
    actionaryList (state, data) {
      state.actionary.actionarylist = data
    },
    editorData (state, data) {
      console.log(data, 'editorData')
      state.editorData = data
    }
  },
  actions: {
    // 获取后台参数 getDoneInfo
    getConfigsDataAction (store) {
      api.$api.configs.configList().then(res => {
        store.commit('getConfigDataMutation', res.data.configList)
        store.commit('changeNetwork', true)
      })
    },
    // 获取有效班级
    getClassesDataAction (store, obj) {
      // 参数要视api接口函数而定
      api.$api.classes.classesList(obj).then(res => {
        console.log(res.data.Classes)
        store.commit('getClassesDataMutation', res.data.Classes)
        store.commit('changeNetwork', true)
        localStorage.setItem('classeslist', JSON.stringify(res.data.Classes))
        // localStorage.setItem('getWith', 'classid')
        // api.$msg({ message: '跳转到课程列表' }) Joinclass
        // router.push('/courses')
        // store.commit('classeslistok', true)
      })
    },
    // 获取有效课程类别
    getCategaryDataAction (store, obj) {
      api.$api.categary.categaryList(obj).then(res => {
        store.commit('getCategaryDataMutation', res.data.categeryList)
        store.commit('changeNetwork', true)
      }).catch(errorHandle => {
        console.log(errorHandle)
      })
    },
    // 登录
    loginAction (store, obj) {
      api.$api.login.login(obj).then(res => {
        // console.log(res.data)
        store.commit('changeLogin', res.data)
        store.commit('changeNetwork', true)
      }).catch(errorHandle => {
        console.log(errorHandle)
      })
    },
    async isJoinclass (store, obj) {
      const res = await api.$api.user.isJoinclass(obj)
      console.log(res.data, 'isJoinclass')
      store.commit('isJoinclass', res.data)
      if (res.data.classid > 0 && !res.data.isgrauted) {
        localStorage.setItem('getWith', 'classid')
        localStorage.setItem('classid', res.data.classid)
        localStorage.setItem('isJoinclass', JSON.stringify(res.data))
        // api.$msg({ message: '班级：' + res.data.classname })
        // router.push('/courses')
      } else {
        api.$msg({ message: '先去报名吧！' })
        // router.push('/joinclass')
      }
    },
    // 报名
    async Joinclass (store, obj) {
      const res = await api.$api.user.Joinclass(obj)
      store.commit('isJoinclass', res.data)
      api.$msg({ message: res.data.msg })
      if (res.data.status) {
        localStorage.setItem('getWith', 'classid')
        localStorage.setItem('classid', res.data.classid)
        localStorage.setItem('isJoinclass', JSON.stringify(res.data))
        store.commit('classid', res.data.classid)
        router.push('/buddhism')
      }
    },
    // 注册
    async registerAction (store, obj) {
      const res = await api.$api.register.register(obj)
      store.commit('changeLogin', res.data)
    },
    // 获取个人信息
    getUserInfoAction (store, obj) {
      api.$api.user.userinfo(obj).then(res => {
        // console.log(res.data.userinfo)
        store.commit('getUserInfoMutation', res.data.userinfo)
      }).catch(errorHandle => {
        console.log(errorHandle)
      })
    },
    // 根据班级id 或者  类别ID 获取课程列表 getCoursesListAction
    getCoursesListAction (store, obj) {
      api.$api.courses.CoursesList(obj).then(res => {
        // console.log(res.data, 'back courselist getWith') courseByClassidList
        store.commit('getCourseListMutation', res.data)
        store.commit('changeNetwork', true)
        if (res.data.length === 1) {
          this.$msg('跳转到课程')
          localStorage.setItem('StudyCourseId', res.data[0].id)

          router.push('/course')
        }
      }).catch(errorHandle => {
        console.log(errorHandle)
      })
    },
    getCourseDetailAction (store, obj) {
      api.$api.courses.CourseDetail(obj).then(res => {
        // console.log(res.data, 'back courseDetail ******')
        store.commit('getCourseDetailMutation', res.data)
        store.commit('changeNetwork', true)
      }).catch(errorHandle => {
        console.log(errorHandle)
      })
    },
    getPointslitAction (store, obj) {
      store.commit('pointslistloading', true)
      api.$api.courses.PointsList(obj).then(res => {
        // console.log(res.data, 'Pointslist')
        store.commit('getPointslistMutation', res.data)
        localStorage.setItem('pointslist', JSON.stringify(res.data))
        store.commit('pointslistloading', false)
        store.commit('changeNetwork', true)
      }).catch(errorHandle => {
        console.log(errorHandle)
      })
    },
    getPointDetailAction (store, obj) {
      api.$api.courses.PointInfo(obj).then(res => {
        // console.log(res.data, 'Pointslist')
        store.commit('getPointMutation', res.data)
        localStorage.setItem('pointInfo', JSON.stringify(res.data))
        store.commit('changeNetwork', true)
      }).catch(errorHandle => {
        console.log(errorHandle)
      })
    },

    async getDoneInfo (store, obj) {
      const res = await api.$api.test.getDoneInfo(obj)

      localStorage.setItem('objid', res.data.studyInfo.objid)
      localStorage.setItem('questiontype', res.data.studyInfo.type)
      localStorage.setItem('question', JSON.stringify(res.data.question))
      localStorage.setItem('doneInfo', JSON.stringify(res.data.studyInfo))

      store.commit('updateAllOkMutation', res.data.AllOk)
      store.commit('getDoneInfoMutation', res.data.studyInfo)
      store.commit('getQuestionInfoMutation', res.data.question)

      store.commit('changeNetwork', true)
    //   store.commit('getDoneInfoFinshed', true) getNumOfQuestions
    },
    getNumOfQuestions (store, obj) {
      api.$api.test.getNumOfQuestions(obj).then(res => {
        // console.log(res.data, 'getNumOfQuestions')
        store.commit('getNumOfQuestionsMutation', res.data)
        store.commit('changeNetwork', true)
      }).catch(errorHandle => {
        console.log(errorHandle)
      })
    },
    async getQuestionInfo (store, obj) {
      const res = await api.$api.test.getQuestionInfo(obj)
      localStorage.setItem('question', JSON.stringify(res.data))
      store.commit('getQuestionInfoMutation', res.data)
      store.commit('changeNetwork', true)
    },
    async updateStudyInfo (store, obj) {
      const res = await api.$api.test.updateStudyInfo(obj)
      store.commit('updateStudyInfoMutation', res.data.updatestatus)
      store.commit('changeNetwork', true)
    },
    async makePaper (store, obj) {
      const res = await api.$api.test.makePaper(obj)
      store.commit('makePaperMutation', res.data)
      // 获取取试题  保存在本地
      // 先获取本地数据
      var localPapers = localStorage.getItem('papers')
      // 如果没有 则为空数组
      var localPapersArr = []
      if (localPapers !== null) {
        localPapersArr = JSON.parse(localPapers)
      }
      // 追加
      localPapersArr.push(res.data)
      store.commit('papers', localPapersArr)
      // 保存
      localStorage.setItem('papers', JSON.stringify(localPapersArr))
      store.commit('changeNetwork', true)
    },
    async getPapreQuestionInfo (store, obj) {
      const res = await api.$api.test.getQuestionInfo(obj)
      store.commit('getPaperQuestionInfoMutation', res.data)
      localStorage.setItem('testQuestion', JSON.stringify(res.data))
      console.log(res.data)
      store.commit('changeNetwork', true)
    },
    async scoretotal (store, obj) {
      const res = await api.$api.test.scoretotal(obj)
      console.log(res.data)
      if (res.data.status) {
        store.commit('makePaperMutation', '')
        store.commit('getPaperQuestionInfoMutation', '')
        store.commit('uidPaperInfo', '')
        localStorage.setItem('testQuestion', [])
        localStorage.setItem('paper', [])
        localStorage.setItem('uidPaperInfo', '[]')
        store.commit('changeNetwork', true)
      }
    },
    async getClockinListAction (store, obj) {
      const res = await api.$api.clockin.getClockinList(obj)
      store.commit('ClockinList', res.data.data)
      localStorage.setItem('ClockinList', JSON.stringify(res.data.data))
      console.log(res.data.data)
      store.commit('changeNetwork', true)
    },
    async getClockinAction (store, obj) {
      const res = await api.$api.clockin.getClockin(obj)
      store.commit('getClockinMutation', res.data)
      localStorage.setItem('signin', JSON.stringify(res.data))
      store.commit('changeNetwork', true)
    },
    async getDkAction (store, obj) {
      const res = await api.$api.clockin.dk(obj)
      if (res.data.status) {
        this.data = {
          uid: localStorage.getItem('uid'),
          clockin: res.data.clockid
        }
        // 更新当前课程
        api.$api.clockin.getClockin(this.data).then(result => {
          // console.log(res.data, 'getNumOfQuestions')
          store.commit('getClockinMutation', result.data)
          localStorage.setItem('signin', JSON.stringify(result.data))
          store.commit('changeNetwork', true)
        }).catch(errorHandle => {
          console.log(errorHandle)
        })
        // 更新课程列表
        // api.$api.clockin.clockinList(this.data).then(results => {
        //   store.commit.ClockinList('ClockinList', results.data.data)
        // })
        api.$msg({ message: '打卡成功，随喜功德...' })
        // this.$msg('')
      }
    },
    async getTestInfo (store, obj) {
      const res = await api.$api.test.getTestInfo(obj)
      store.commit('TestinfoMutation', res.data)
      store.commit('changeNetwork', true)
      localStorage.setItem('TestInfo', JSON.stringify(res.data))
    },
    async Allclock (store, obj) {
      const res = await api.$api.systeminfo.Allclock(obj)
      console.log(res.data)
      store.commit('Allclock', res.data)
      store.commit('changeNetwork', true)
    },
    async thisMonth (store, obj) {
      const res = await api.$api.systeminfo.thisMonth(obj)
      console.log(res.data)
      store.commit('thisMonth', res.data)
      store.commit('changeNetwork', true)
    },
    async Today (store, obj) {
      const res = await api.$api.systeminfo.Today(obj)
      console.log(res.data)
      store.commit('Today', res.data)
      store.commit('changeNetwork', true)
    },
    async test (store, obj) {
      const res = await api.$api.systeminfo.test(obj)
      console.log(res.data)
      store.commit('test', res.data)
      store.commit('changeNetwork', true)
    },
    async updateScore (store, obj) {
      const res = await api.$api.user.updateScore(obj)
      store.commit('updateScore', obj)
      //   updateScore (state, data) { getClassesDataAction
      //     if (data.feild === 'wisdom') { state.userInfo.wisdom = state.userInfo.wisdom + data.score }
      //     if (data.feild === 'ford') { state.userInfo.ford = state.userInfo.wisdom + data.score }
      //   }
      console.log(res)
    },
    // ----0519
    async getCategories (store, obj) {
      const res = await api.$api.article.getCategories(obj)
      localStorage.setItem('categories', JSON.stringify(res.data))
      store.commit('getCategories', res.data)
      store.commit('changeNetwork', true)
    },
    async getCategoryByCatID (store, obj) {
      const res = await api.$api.article.getCategoryByCatID(obj)
      localStorage.setItem('categoryID', JSON.stringify(res.data))
      store.commit('getCategoryByCatID', res.data)
      store.commit('changeNetwork', true)
    },
    async getArticleInfo (store, obj) {
      const res = await api.$api.article.getArticleInfo(obj)
      localStorage.setItem('article', JSON.stringify(res.data))
      console.log(res.data)
      store.commit('getArticleInfo', res.data)
      store.commit('changeNetwork', true)
    },
    async getVideoSrc (store, obj) {
      const res = await api.$api.getvideosrc.Getvideosrc(obj)
      // localStorage.setItem('article', JSON.stringify(res.data))playervideo: false,
      // video: {
      //     videosrc: ''
      //   },
      console.log(res.data)
      store.commit('getVideoSrc', res.data)
      //   store.commit('playervideo', true)
      store.commit('changeNetwork', true)
    },
    async getTengXunVideoSrc (store, obj) {
      const res = await api.$api.gettengxunvideo.Gettengxunvideo(obj)
      // localStorage.setItem('article', JSON.stringify(res.data))playervideo: false,
      // video: {
      //     videosrc: ''
      //   },getTengXunVideoSrc
      console.log(res.data, 'getTengXunVideoSrc')
      store.commit('getVideoSrc', res.data)
      //   store.commit('playervideo', true)
      store.commit('changeNetwork', true)
    },
    async updateStudyTime (store, obj) {
      const res = await api.$api.courses.updateStudyTime(obj)
      //   store.commit('updateStudyTime', res.data)
      console.log(res)
    },
    async updateZyStudyTimes (store, obj) {
      const res = await api.$api.courses.ZyIncTimes(obj)
      console.log(res.data, 'updateZyStudyTimes')
    },
    async actionaryList (store, obj) {
      const res = await api.$api.actionary.actionaryList(obj)
      localStorage.setItem('actionaryList', JSON.stringify(res.data))
      store.commit('actionaryList', res.data)
      store.commit('changeNetwork', true)
    },
    async joinActionary (store, obj) {
      const res = await api.$api.actionary.joinActionary(obj)
      //   store.commit('joinActionary', res.data)
      console.log(res.data)
      store.commit('changeNetwork', true)
    }
  },
  modules: {
  }
})
