<template>
  <div id="content" :style="backgroundDiv">
    <div class="title">圈子</div>
    <div v-if="this.classid">
      <div v-if="this.actionarylist">
        <ActionaryList :actionarylist="actionarylist"></ActionaryList>
      </div>
    </div>

    <div v-else>
      <van-notice-bar left-icon="volume-o" text="没有报名？" />
      <van-cell :title="title" is-link class="vancell" @click="joinclass" />
    </div>
  </div>
</template>

<script>
// import Game from '@/components/game/Game'
// import Systeminfo from '@/components/common/Systeminfo'
import ActionaryList from '@/components/actionary/ActionaryList'
export default {
  components: {
    ActionaryList
    // this.$store.dispatch('ActionaryList', {
    //   uid: 1
    // })
    // this.$store.dispatch('joinActionary', {
    //   uid: 1,
    //   actionaryid: 1
    // })

    // actionary: {
    //   actionarylist: []
    // },
  },
  data () {
    return {
      classid: '',
      title: '先去报名吧',
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/pres.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      height: ''
    }
  },
  mounted () {
    this.classid = localStorage.getItem('classid')

    if (this.actionarylist === undefined || (typeof this.actionarylist === 'object' && this.actionarylist.length === 0)) {
      this.uid = localStorage.getItem('uid')
      this.$store.dispatch('actionaryList', {
        uid: this.uid
      })
      //   console.log(this.$store.state.article.catlist, 'state catlist')
    }

    console.log(this.actionartlist, 'index actionarylist')
  },
  computed: {
    actionarylist () {
      let actionariesList = this.$store.state.actionary.actionarylist// .courselist
      if (actionariesList === null) {
        actionariesList = []
      }
      //   console.log(courseByClassidList, 'dddd')
      if (actionariesList !== []) {
        return actionariesList
      } else {
        actionariesList = localStorage.getItem('actionaryList')
      }
      var actionariesListArr = []
      if (actionariesList !== null) {
        actionariesListArr = JSON.parse(actionariesList)
      }
      return actionariesListArr
    }
  },
  methods: {
    joinclass () {
      this.$router.push('/joinclass')
    }
  }
}
</script>

<style lang="scss" scoped>
#content {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 188.889vw;
}
.title {
  color: white;
  background-color: rgb(70, 27, 27);
  line-height: 5.556vw;
  font-size: 4.167vw;
  height: 9.722vw;
  margin: 0 auto;
  padding-top: 4.167vw;
  text-align: center;
}
.vancell {
  //   border-top: 0.278vw #ccc solid;
  background-color: rgba(255, 255, 255, 0.959);
  margin-top: 4.167vw;
  margin-bottom: 2.778vw;
}
::v-deep .van-cell__title {
  color: chocolate;
}
</style>
