<template>
  <div v-if="this.courses">
    <li v-for="course in this.courses" :key="course.id">
      <div class="course" @click="toCourse(course.id)">
        <van-row>
          <van-col span="24">
            <img
              v-if="course.pic"
              :src="course.pic"
              alt=""
              width="100%"
              height="120vw"
            />
            <img v-else src="@/assets/logo.png" width="100%" height="125vw" />
          </van-col>
        </van-row>
        <div class="course-top">
          <van-tag type="danger"
            >{{ course.catname }}
            <van-icon
              name="label"
              class="badge-icon pdlft"
              v-if="course.ctype == 1"
            />
            <van-icon
              name="comment"
              class="badge-icon pdlft"
              v-if="course.ctype == 2"
            />
            <van-icon
              name="video"
              class="badge-icon pdlft"
              v-if="course.ctype == 3"
            />
            <van-icon
              name="coupon"
              class="badge-icon pdlft"
              v-if="course.ctype == 4"
            />
          </van-tag>
        </div>
        <div class="course-bottom">
          <div class="course-title">
            {{ course.cname }}
            / {{ course.name }}
          </div>
          <div class="van-ellipsis van-card__title">
            <van-icon name="user-circle-o" /> {{ course.views }}人次
          </div>
        </div>
      </div>
    </li>
  </div>
  <img v-else src="@/assets/syx.png" width="100%" alt="" />
</template>

<script>
export default ({
  props: ['courses'],
  data () {
    return {
      //   content: ' 题'
    }
  },
  methods: {
    toCourse (id) {
      localStorage.setItem('StudyCourseId', id)
      this.$router.push({ name: 'course', params: { id: id } })
    },
    toTest (id) {
      localStorage.setItem('TestCourseId', id)
      this.$router.push('/test')
    }
  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', true)
  }

})
</script>

<style lang="scss" scoped>
li,
div,
img {
  margin: 0vw;
  padding: 0vw;
}
.course {
  margin: 0vw;
  padding: 1.389vw;
  margin-bottom: -2.778vw;
  overflow: hidden;
  position: relative;
  //   background-color: black;
  .course-top {
    position: absolute;
    top: 2.778vw;
  }
  .course-bottom {
    position: absolute;
    bottom: 0vw;
    height: 16.667vw;
    padding-left: 2.778vw;
    width: 100%;
    .course-title {
      color: white;
      font-size: 3.611vw;
      padding: 0.556vw;
    }
    .van-card__title {
      font-size: 3.056vw;
      color: white;
      padding-left: 5.556vw;
      padding-top: 1.389vw;
    }
  }
}
.pdlft {
  padding-left: 5px;
}
</style>
