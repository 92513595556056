/**
 * article模块接口列表
 */

import base from './base' // 导入接口域名列表
// import axios from 'axios' // 导入http中创建的axios实例
import axios from '@/request/http' // 导入http中创建的axios实例
// import qs from 'qs' // 根据需求是否导入qs模块

const getvideosrc = {
  // 类别列表
  Getvideosrc (params) {
    return axios.get(`${base.getvideosrc}/getVideo`, {
      params: params
    })
  }
  // post提交
//   login (params) {
//     return axios.post(`${base.sq}/accesstoken`, qs.stringify(params))
//   }
  // 其他接口…………
}

export default getvideosrc
