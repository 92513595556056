import Vue from 'vue'
import VueRouter from 'vue-router'
import Buddhism from '@/views/buddhism/Index'
import Skillful from '@/views/skillful/Index'
import Prescription from '@/views/prescription/Index'
import Categary from '@/views/buddhism/categary/Index'
import ClassesList from '@/views/buddhism/classes/ClassesList'

Vue.use(VueRouter)

const auth = {
  isLogin () {
    const accessToken = localStorage.getItem('accesstoken')
    if (accessToken === '' | accessToken === null) {
      return false
    } else {
      return true
    }
  }
}
const routes = [
  {
    path: '/',
    name: '内明 ',
    redirect: '/buddhism'// 设置默认指向的路径
  },
  {
    path: '/buddhism',
    name: 'buddhism',
    component: Buddhism
  },
  {
    path: '/categary',
    name: 'categary', //,
    component: Categary
  },
  {
    path: '/courses',
    name: 'courses',
    component: () => import('@/views/buddhism/categary/courses/Courses')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('@/views/buddhism/categary/courses/Course')
  },
  {
    path: '/pointinfo',
    name: 'Pointinfo',
    component: () => import('@/views/buddhism/categary/courses/points/Pointinfo')
  },
  {
    path: '/linkhome',
    name: 'LinkHome',
    component: () => import('@/components/course/Outweb')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/buddhism/categary/courses/points/Test')
  },
  {
    path: '/pointTest',
    name: 'pointTest',
    component: () => import('@/views/buddhism/categary/courses/points/Pointtestview')
  },
  {
    path: '/testError',
    name: 'testError',
    component: () => import('@/views/buddhism/categary/courses/points/Testerror')
  },
  {
    path: '/testInfo',
    name: 'testinfo',
    component: () => import('@/views/buddhism/categary/courses/points/Testinfo')
  },
  {
    path: '/classes',
    name: 'classes', //,
    component: ClassesList
  },
  // 个人信息
  {
    path: '/mine',
    name: 'mine',
    component: () => import('@/views/mine/Index'),
    meta: {
      isAuthRequried: true
    }
  },
  {
    path: '/edit',
    name: 'edit',
    component: () => import('@/views/mine/Edit')
  },
  {
    path: '/skillful',
    name: 'skillful',
    component: Skillful
  },
  {
    path: '/categoryid',
    name: 'Categoryid',
    component: () => import('@/views/skillful/Categoryid')
  },
  {
    path: '/article',
    name: 'Article',
    component: () => import('@/views/skillful/Article')
  },
  {
    path: '/prescription',
    name: 'prescription',
    component: Prescription
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Index')
  }, {
    path: '/reg',
    name: 'reg',
    component: () => import('@/views/reg/Index')
  },
  {
    path: '/joinclass',
    name: '/joinclass',
    component: () => import('@/components/common/Joinclass')
  },
  {
    path: '/systeminfo',
    name: 'systeminfo',
    component: () => import('@/components/common/SysinfoIndex')
  },
  {
    path: '/clockinindex',
    name: 'Clockinindex',
    component: () => import('@/components/clockin/Clockinindex')
  },
  {
    path: '/clockin',
    name: 'clockin',
    component: () => import('@/components/clockin/Signclockin')
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('@/views/game/Index')
  }
]

const router = new VueRouter({
//   mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  // if (to.path === '/buddhism') return next()
  // if (to.path === '/skillful') return next()
//   if (to.path === '/prescription') return next()
  if (to.path === '/login') return next()
  if (to.path === '/reg') return next()
  if (auth.isLogin()) {
    return next()
  } else {
    return next('/login')
    // return next()
  }
})
export default router
