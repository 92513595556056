<template>
  <div id="content">
    <div v-if="!network">
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
        我没网了
      </van-notice-bar>
    </div>
    <div v-if="notice">
      <van-notice-bar left-icon="volume-o" :text="notice" />
    </div>
    <div>
      <router-view></router-view>
    </div>

    <van-tabbar
      v-model="active"
      route
      class="main-bar"
      v-show="isShowTabBar"
      @change="changeicon"
    >
      <van-tabbar-item :icon="home" to="/buddhism" :dot="homedot"
        >学习</van-tabbar-item
      >
      <van-tabbar-item :icon="chat" :dot="dot" to="/skillful"
        >广场</van-tabbar-item
      >
      <van-tabbar-item :icon="friends" :badge="newsnum" to="/prescription"
        >圈子</van-tabbar-item
      >
      <van-tabbar-item :icon="manager" :badge="updat" to="/mine"
        >我的</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>

import { mapState } from 'vuex'
export default {
  data () {
    return {
      active: 'buddhism',
      homedot: false,
      dot: false,
      newsnum: '',
      updat: '',
      home: 'eye',
      chat: 'cluster-o',
      friends: 'friends-o',
      manager: 'manager-o'
    }
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', true)
    // var configList = localStorage.getItem('configList')

    if (!localStorage.getItem('configList')) {
      this.$store.dispatch('getConfigsDataAction')
    }
    this.$store.commit('getClassesDataMutation', [])
    this.$store.commit('getCourseListMutation', [])
    //  store.commit('getCourseListMutation', res.data)
  },
  computed: {
    ...mapState(['isShowTabBar']),
    ...mapState(['network']),
    ...mapState(['notice'])
  },
  methods: {
    onRefresh () {
      this.$router.replace('/refresh')
    },
    changeicon (active) {
      //   console.log(active, 'app')
      if (active === 0) {
        this.home = 'eye'
        this.chat = 'cluster-o'
        this.friends = 'friends-o'
        this.manager = 'manager-o'
      } else if (active === 1) {
        this.home = 'eye-o'
        this.chat = 'cluster'
        this.friends = 'friends-o'
        this.manager = 'manager-o'
      } else if (active === 2) {
        this.home = 'eye-o'
        this.chat = 'cluster-o'
        this.friends = 'friends'
        this.manager = 'manager-o'
      } else if (active === 3) {
        this.home = 'eye-o'
        this.chat = 'cluster-o'
        this.friends = 'friends-o'
        this.manager = 'manager'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
body {
  background-color: #d7d5d4;
  padding: 0vw;
  margin: 0vw;
}
ul {
  list-style-type: none;
  padding: 0vw;
  margin: 0vw;
}
li {
  display: inherit;
  padding: 0vw;
  margin: 0vw;
}
::v-deep .van-tabbar {
  background-color: rgba(51, 31, 11, 1);
}
.van-tabbar-item van-tabbar-item--active {
  background-color: rgba(51, 31, 11, 1);
}
.van-tabbar-item--active {
  color: white;
  background-color: rgba(51, 31, 11, 1);
  ::v-deep .van-tabbar-item__icon {
    color: rgb(255, 0, 0);
    background-color: rgba(51, 31, 11, 1);
  }
}
.van-tabbar-item {
  color: white;
  background-color: rgba(48, 31, 31, 0.904);
}
</style>
