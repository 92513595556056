<template>
  <div>
    <div class="course" @click="toActionaryId(actionary.id)">
      <img v-if="actionary.thumb" :src="actionary.thumb" alt="" />
      <img v-else src="@/assets/logo.png" width="100%" height="125vw" />
      <!-- jointype前面不能加this -->
      <div class="course-top" v-if="jointype == 1">
        <van-tag type="danger"
          >正在报名：{{ actionary.actionaryname }}
        </van-tag>
      </div>
      <div class="course-top" v-if="jointype == 2">
        <van-tag type="danger"
          >您已报名：{{ actionary.actionaryname }}
        </van-tag>
      </div>
      <div class="course-top" v-if="jointype == 2">
        <van-tag type="danger"
          >活动结束：{{ actionary.actionaryname }}
        </van-tag>
      </div>
      <div class="course-bottom">
        <div class="course-title">
          报名时间：{{ actionary.regbegintime }}~{{ actionary.regstoptime }}
        </div>
        <div class="van-ellipsis van-card__title">
          活动时间：{{ actionary.actionbegintime }}~{{
            actionary.actionstoptime
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['actionary', 'jointype'],
  beforeMount () {
    // this.$store.commit('hideShowTabbar', false)
  },
  components: {},
  data () {
    return {}
  },
  mounted () {
    console.log(this.actionary, 'actionary actionary')
  },
  methods: {
    toActionaryId (id) {
      console.log(id)

      // this.classid = localStorage.getItem('classid')
      // localStorage.setItem('categoryID', JSON.stringify(res.data))
      //   article: {
      //   catlist: [],
      //   catid: [],
      //   this.$router.push('/categoryid')
    }
  },
  computed: {

  }
}
</script>
<style lang="scss" scoped>
li,
div,
img {
  margin: 0vw;
  padding: 0vw;
}
.course {
  margin: 0vw;
  padding: 1.389vw;
  margin-bottom: -2.778vw;
  overflow: hidden;
  position: relative;
  //   background-color: black;
  .course-top {
    position: absolute;
    top: 2.778vw;
  }
  img {
    width: 100%;
    height: 30.86vw;
  }
  .course-bottom {
    position: absolute;
    bottom: 0vw;
    height: 16.667vw;
    padding-left: 2.778vw;
    width: 100%;
    .course-title {
      color: white;
      font-size: 3.611vw;
      padding: 0.556vw;
    }
    .van-card__title {
      font-size: 3.056vw;
      color: white;
      padding-left: 5.556vw;
      padding-top: 1.389vw;
    }
  }
}
</style>
