<template>
  <div>
    <div class="catlist">
      <div v-if="this.actionarylist">
        <div v-if="actionarylist.countjoining > 0">
          <div v-for="joinin in actionarylist.joining" :key="joinin.id">
            <Joining
              :actionary="joinin"
              :jointype="acjonin"
              :key="acjonin"
            ></Joining>
          </div>
        </div>
        <div v-if="this.actionarylist.counthasjoin > 0">
          <div v-for="hasjoin in this.actionarylist.hasjoin" :key="hasjoin.id">
            <Joining
              :actionary="hasjoin"
              :jointype="achasjoin"
              :key="achasjoin"
            ></Joining>
          </div>
        </div>
        <div v-if="this.actionarylist.countover > 0">
          <div v-for="hasover in this.actionarylist.hasover" :key="hasover.id">
            <Joining
              :actionary="hasover"
              :jointype="achasover"
              :key="achasover"
            ></Joining>
          </div>
        </div>
      </div>
      <van-loading v-else size="24px" color="#0094ff" vertical class="margintop"
        >资源加载中...</van-loading
      >
    </div>
    <!-- <div v-for="actions in actionartList" :key="actions.id">
      <Actions :actions="actions"> </Actions>
    </div> -->
  </div>
</template>

<script>
// import Actions from '@/components/actionary/Actions'
import Joining from '@/components/actionary/Joining'
// import Pointslist from '@/components/course/Pointslist'
export default ({
  components: {
    Joining
  },
  data () {
    return {
      acjonin: 1,
      achasjoin: 2,
      achasover: 3
    }
  },
  mounted () {
    console.log(this.actionarylist, 'aaa actionarylist')
  },
  props: ['actionarylist']
})
</script>
