/**
 * test模块接口列表
 */

import base from './base' // 导入接口域名列表
// import axios from 'axios' // 导入http中创建的axios实例
import axios from '@/request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const test = {
  // 试题数量
  getNumOfQuestions (params) {
    return axios.get(`${base.test}/getNumOfQuestions`, {
      params: params
    })
  },
  getDoneInfo (params) {
    return axios.get(`${base.test}/getDoneInfo`, {
      params: params
    })
  },
  getQuestionInfo (params) {
    return axios.get(`${base.test}/getQuesttionInfo`, {
      params: params
    })
  },
  updateStudyInfo (params) {
    return axios.get(`${base.test}/updateStudyInfo`, {
      params: params
    })
  },
  makePaper (params) {
    return axios.get(`${base.test}/makePaper`, {
      params: params
    })
  },
  scoretotal (data) {
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    return axios.post(`${base.test}/scoretotal`, qs.stringify(data))
  },
  getTestInfo (params) {
    return axios.get(`${base.test}/getTestInfo`, {
      params: params
    })
  },
  systeminfo (params) {
    return axios.get(`${base.test}/systeminfo`, {
      params: params
    })
  }
  // 其他接口………… post(`${base.login}/login`, qs.stringify(params))
}

export default test
