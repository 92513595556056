import 'babel-polyfill'

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import api from '@/api/index' // 导入api接口

// 引入全部组件
import Mint, { Toast } from 'mint-ui'
import 'mint-ui/lib/style.css'

// 我选择了全局安装。
import Vant from 'vant'
import 'vant/lib/index.css'

import CKEditor from '@ckeditor/ckeditor5-vue2'

import Mui from 'vue-awesome-mui'
import VueHtml5Plus from 'vue-html5plus'
import VideoPlayer from 'vue-video-player'
// import
import VueCoreVideoPlayer from 'vue-core-video-player'

import Back from '@/js/Back'

import VueLazyload from 'vue-lazyload'
require('es6-promise').polyfill()
// Vue.config.productionTip = process.env.NODE_ENV === 'production' // production生产环境 development 开发环境

Vue.use(VueLazyload)

Vue.use(VueCoreVideoPlayer)
// or with options
const loadimage = require('@/assets/syx.png')
const errorimage = require('@/assets/syx.png')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
})

Vue.config.productionTip = false
Vue.use(Mui)
Vue.use(VueHtml5Plus)
// import eventStorage from '@/utils/tools'
// Vue.use(eventStorage)
// 为了解决setItemEvent不立即生效，使用事件派发强制更新
// eventStorage.dispatchEventStorage()
Vue.use(CKEditor)
Vue.use(VideoPlayer)

var VueTouch = require('vue-touch')
Vue.use(VueTouch, { name: 'v-touch' })

Vue.use(Mint)
Vue.use(Vant)
Vue.prototype.$api = api // 将api挂载到vue的原型上复制代码
Vue.prototype.$msg = Toast
// Vue.prototype.$eventStorage = eventStorage

Vue.config.productionTip = false
if (module.hot) {
  module.hot.accept()
}
new Vue({
  router,
  store,
  Back,
  render: h => h(App)
}).$mount('#app')
