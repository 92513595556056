/**
 * article模块接口列表
 */

import base from './base' // 导入接口域名列表
// import axios from 'axios' // 导入http中创建的axios实例
import axios from '@/request/http' // 导入http中创建的axios实例
// import qs from 'qs' // 根据需求是否导入qs模块

const classes = {
  // 班级列表
  classesList (params) {
    return axios.get(`${base.study}/getCousrsesOfClasses`, {
      params: params
    })
  },
  // 班级详情,演示
  classesDetail (id, params) {
    return axios.get(`${base.study}/getCousrseForClassid/${id}`, {
      params: params
    })
  },
  // 班级详情,演示
  CourseDetail (id, params) {
    return axios.get(`${base.study}/getCoursePointsByCourseid`, {
      params: params
    })
  }
  // 其他接口…………
}

export default classes
