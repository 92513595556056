/**
 * article模块接口列表
 */

import base from './base' // 导入接口域名列表
// import axios from 'axios' // 导入http中创建的axios实例
import axios from '@/request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const user = {

  // 注销
  userinfo (params) {
    return axios.get(`${base.user}/userinfo`, {
      params: params
    })
  },
  // post提交
  UserUpdate (id, params) {
    return axios.post(`${base.user}/UserUpdate/${id}`, qs.stringify(params))
  },
  isJoinclass (params) {
    return axios.get(`${base.user}/isJoinclass`, {
      params: params
    })
  },
  Joinclass (params) {
    return axios.get(`${base.user}/joinclassByClassid`, {
      params: params
    })
  },
  updateScore (params) {
    return axios.get(`${base.user}/updateScore`, {
      params: params
    })
  }

}

export default user
