// import router from '../router/index'
// console.log(router.currentRoute.fullPath, 'back-------')
// 监听手机物理返回键的事件
document.addEventListener('plusready', function () {
//   var first = null
  window.plus.navigator.setStatusBarStyle('light')
  var webview = window.plus.webview.currentWebview()
  window.plus.key.addEventListener('backbutton', function () {
    webview.canBack(function (e) {
      if (e.canBack) {
        // webview.back(-1) // 返回上一页
        // if (router.currentRoute.fullPath === '/pointinfo') {
        //   router.replace({
        //     path: '/course'
        //   })
        // }
        // console.log(router, 'back-------')
        this.$router.go(-1)
      } else {
        var r = confirm('您确定要离开吗?')
        if (r === true) {
          webview.close() // close
        }
      }
    })
  })
})
