/**
 * categary模块接口列表
 */

import base from './base' // 导入接口域名列表
// import axios from 'axios' // 导入http中创建的axios实例
import axios from '@/request/http' // 导入http中创建的axios实例
// import qs from 'qs' // 根据需求是否导入qs模块

const courses = {
  // 类别列表 根据 classid  或 catid
  CoursesList (params) {
    return axios.get(`${base.courses}/getCourseList`, {
      params: params
    })
  },
  // 详情
  CourseDetail (params) {
    return axios.get(`${base.courses}/courseDetail`, {
      params: params
    })
  },
  PointsList (params) {
    return axios.get(`${base.courses}/points`, {
      params: params
    })
  },
  PointInfo (params) {
    return axios.get(`${base.courses}/pointInfo`, {
      params: params
    })
  },
  getSrc (params) {
    return axios.get(`${base.courses}/getVideoSrc`, {
      params: params
    })
  },
  updateStudyTime (params) {
    return axios.get(`${base.courses}/updateStudyTime`, {
      params: params
    })
  },
  ZyIncTimes (params) {
    return axios.get(`${base.courses}/ZyIncTimes`, {
      params: params
    })
  }
  // 其他接口…………
}

export default courses
