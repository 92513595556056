/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios'
import router from '../router/index'
import store from '../store/index'
import { Toast } from 'mint-ui'

/**
  * 提示函数
  * 禁止点击蒙层、显示一秒后关闭
  */
const tip = msg => {
  Toast({
    message: msg,
    duration: 1000,
    forbidClick: true
  })
}

/**
  * 跳转登录页
  * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
  */
const toLogin = () => {
  router.replace({
    path: '/login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  })
}

/**
  * 请求失败后的错误统一处理
  * @param {Number} status 请求失败的状态码
  */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      toLogin()
      break
      // 403 token过期
      // 清除token并跳转登录页
    case 403:
      tip('登录过期，请重新登录')
      localStorage.removeItem('access_token')
      //   store.commit('loginSuccess', null)
      setTimeout(() => {
        toLogin()
      }, 1000)
      break
      // 404请求不存在
    case 404:
      tip('请求的资源不存在')
      break
    case 500:
      tip('网络有问题，可能断网了')
      store.commit('changeNetwork', false)
      break
    case 203:
      tip('请求非法')
      break
    case 410:
      tip('用户名不存在或者密码错误')
      break
    default:
      tip(other)
      //   store.commit('changeNetwork', false)
      console.log(other, '-----')
  }
}

// 创建axios实例
var instance = axios.create({
//   baseURL: process.env.VUE_APP_BASE_API, // '/',
  timeout: 1000 * 12
})
// // 设置post请求头
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// instance.defaults.headers.post['Access-Control-Allow-Origin'] = 'https://app.sila.ink'
// instance.defaults.headers.get['Access-Control-Allow-Origin'] = 'https://app.sila.ink'
// instance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
/**
  * 请求拦截器
  * 每次请求前，如果存在token则在请求头中携带token
  */
instance.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。 "", "true"  Authorization
    if (localStorage.getItem('accesstoken') && localStorage.getItem('uid')) {
      config.headers.accesstoken = 'SilaInk ' + localStorage.getItem('accesstoken')
    }
    config.headers.datatoken = 'SilaInk_Data_51a69fb150d149fa902933e308901188'
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    config.headers.Authorization = 'SilaInk_Data_51a69fb150d149fa902933e308901188'
    config.headers['Access-Control-Allow-Origin'] = 'https://app.sila.ink'
    config.headers['Access-Control-Allow-Credentials'] = true
    config.headers['Access-Control-Allow-Methods'] = 'GET,POST,PUT,DELETE,OPTIONS,PATCH'
    config.headers['Access-Control-Allow-Headers'] = 'Access-Control-Allow-Methods, Access-Control-Allow-Headers, Access-Control-Allow-Credentials, Access-Control-Allow-Origin, Origin, Accept, Authorization,DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control, Content-Type, X-Access-Token, datatoken, accesstoken'
    return config
  },
  error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => res.status === 200 ? Promise.resolve(res) : Promise.reject(res),
  // 请求失败
  error => {
    const { response } = error
    // console.log('111555555', response)
    errorHandle(response.request.status, response.request.statusText)
    //   console.log('111555555', response.request)
    return Promise.reject(response)
    // if (response) {
    //   // 请求已发出，但是不在2xx的范围

    // }
  })

export default instance
