/**
 * article模块接口列表
 */

import base from './base' // 导入接口域名列表
// import axios from 'axios' // 导入http中创建的axios实例
import axios from '@/request/http' // 导入http中创建的axios实例
// import qs from 'qs' // 根据需求是否导入qs模块

const actionary = {
  //
  actionaryList (params) {
    return axios.get(`${base.actionary}/index`, {
      params: params
    })
  },
  //
  joinActionary (params) {
    return axios.get(`${base.actionary}/joinActionary`, {
      params: params
    })
  }
}

export default actionary
