/**
 * api接口的统一出口
 */
// 文章模块接口
import article from '@/api/article'

import categary from '@/api/categary'
import login from '@/api/login'
import classes from '@/api/classes'
import register from '@/api/register'
import configs from '@/api/configs'
import user from '@/api/user'
import upload from '@/api/upload'
import courses from '@/api/courses'
import test from '@/api/test'
import clockin from '@/api/clockin'
import systeminfo from '@/api/systeminfo'
import actionary from '@/api/actionary'
import getvideosrc from '@/api/getvideosrc'
import gettengxunvideo from '@/api/gettengxunvideo'
// 其他模块的接口……

// 导出接口
export default {
  article, categary, login, classes, register, configs, user, upload, courses, test, clockin, systeminfo, actionary, getvideosrc, gettengxunvideo
  // ……
}
