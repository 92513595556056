<template>
  <ul v-if="catLength">
    <li
      v-for="categary in $store.state.study.categaryList"
      :key="categary.id"
      @click="getCourseOfcatid(categary.id, categary.catname)"
      :style="backgroundDiv"
    >
      <a href="javascript:;">
        {{ categary.catname }}
      </a>
    </li>
  </ul>
  <van-loading v-else size="6.667vw" vertical>课程类别加载中...</van-loading>
</template>

<script>
export default {
  data () {
    return {
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/categarybk.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }
    }
  },
  props: [
    'id'
  ],
  beforeMount () {
    this.$store.commit('hideShowTabbar', true)
  },
  mounted () {
    // if (this.$store.state.isJoinclass.classid > 0 && !this.$store.state.isJoinclass.isgrauted) {
    //   localStorage.setItem('getWith', 'classid')
    //   this.$router.push('/buddhism')
    // } else {
    //   this.$msg('请先报名。')
    //   this.$router.push('/mine')
    // }
    if (this.$store.state.study.categaryList.length === 0) {
      this.$store.dispatch('getCategaryDataAction', { id: this.id })
    }
  },
  computed: {
    catLength () {
      if (this.$store.state.study.categaryList.length > 0) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    getCourseOfcatid (id, name) {
      localStorage.setItem('categaryId', id)
      localStorage.setItem('categaryName', name)
      localStorage.setItem('getWith', 'catid')
      this.$router.push('/courses')
    }
  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', false)
  }
}
</script>

<style lang='scss' scoped>
ul,
li {
  padding: 0vw;
  margin: 0vw;
  list-style: none;
}
ul {
  background-color: rgba(214, 231, 212, 0.904);
  text-align: left;
  li {
    height: 16.667vw;
    padding: 8.333vw;
    a {
      color: #f7ce7c;
      //   font-weight: bold;
    }
  }
}
</style>
