/**
 * article模块接口列表
 */

import base from './base' // 导入接口域名列表
// import axios from 'axios' // 导入http中创建的axios实例
import axios from '@/request/http' // 导入http中创建的axios实例

const upload = {

  // post提交
  FileUpload (params) {
    return axios.post(`${base.upload}/local_upload/`, params)
  }
}

export default upload
