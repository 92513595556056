<template>
  <div id="content" :style="backgroundDiv">
    <span v-if="this.classid">
      <Courses></Courses>
    </span>
    <div v-else>
      <van-notice-bar left-icon="volume-o" text="没有报名？" />
      <van-cell :title="title" is-link class="vancell" @click="joinclass" />
    </div>
    <!-- <Categary class="Categarylist"></Categary> -->
  </div>
</template>

<script>
// import Categary from '@/views/buddhism/categary/Index'
import Courses from '@/views/buddhism/categary/courses/Courses'
export default {
  data () {
    return {
      classid: '',
      title: '先去报名吧',
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/siwei.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }
    }
  },
  components: {
    Courses
    // Categary
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', true)
  },
  mounted () {
    this.classid = localStorage.getItem('classid')
  },
  methods: {
    joinclass () {
      this.$router.push('/joinclass')
    }
  },
  watch: {
    geIsJoinClass () {
      if (this.geIsJoinClass.classid > 0 && !this.geIsJoinClass.isgrauted) {
        localStorage.setItem('getWith', 'classid')
        //   this.$router.push('/buddhism')
      } else {
        this.$msg('请先报名。')
        this.$router.push('/joinclass')
      }
    }
  },
  computed: {
    geIsJoinClass () {
      let isJoinclass = this.$store.state.study.isJoinclass
      //   var isJoinclass = localStorage.getItem('isJoinclass') u
      if (isJoinclass === '') { isJoinclass = null } else {
        return isJoinclass
      }
      var isJoinclassArr = []
      isJoinclass = localStorage.getItem('isJoinclass')
      if (isJoinclass !== null) {
        isJoinclassArr = JSON.parse(isJoinclass)
      }
      return isJoinclassArr
    }
  }
}
</script>

<style lang="scss" scoped>
#content {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 188.889vw;
}
// .Categarylist {
//   margin-top: 1.389vw;
// }
.vancell {
  //   border-top: 0.278vw #ccc solid;
  background-color: rgba(255, 255, 255, 0.959);
  margin-top: 4.167vw;
  margin-bottom: 2.778vw;
}
::v-deep .van-cell__title {
  color: chocolate;
}
</style>
