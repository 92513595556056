/**
 * 接口域名的管理
 */
const base = {
  study: 'https://api.sila.ink/api.php/Study',
  //   sq: 'https://api.sila.ink/Api.php/Study/getCousrsesOfClasses',
  login: 'https://api.sila.ink/api.php/Login',
  register: 'https://api.sila.ink/api.php/Register',
  configs: 'https://api.sila.ink/api.php/Configs',
  user: 'https://api.sila.ink/api.php/Mycenter',
  upload: 'https://api.sila.ink/api.php/FileUpload',
  courses: 'https://api.sila.ink/api.php/Courses',
  test: 'https://api.sila.ink/api.php/Test',
  clockin: 'https://api.sila.ink/api.php/Clockin',
  systeminfo: 'https://api.sila.ink/api.php/Systeminfo',
  article: 'https://api.sila.ink/api.php/Article',
  actionary: 'https://api.sila.ink/api.php/Actionary',
  getvideosrc: 'https://api.sila.ink/api.php/Getvideosrc',
  gettengxunvideo: 'https://api.sila.ink/api.php/Gettengxunvideo'
}

export default base
